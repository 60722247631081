import React from 'react';
import styled from 'styled-components';

export const BaseContainer = ({ backgroundColor, children }) => {
  return (
    <StyledContainer backgroundColor={backgroundColor}>
      <StyledGrid>{children}</StyledGrid>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
`;
const StyledGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  @media only screen and (min-width: 1824px) {
    width: 1920px;
    grid-column-gap: 14px;
  }
`;
