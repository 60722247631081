import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../styleconstants';
import { BaseContainer } from './shared/BaseContainer';
import Burger from './Burger';
import { fadeIn } from './shared/Animations';

const Header = ({ handleClick, isOpen }) => {
  return (
    <BaseContainer>
      <StyledHeader>
        <StyledLink delay='3000ms' islogo={true} to='/'>
          NEW LEGACY
        </StyledLink>

        <Burger
          delay='3000ms'
          isOpen={isOpen}
          handleClick={handleClick}
        ></Burger>

        <LinkContainer>
          <StyledLink delay='3200ms' to='/manifesto'>
            OUR MANIFESTO
          </StyledLink>

          <StyledLink delay='3400ms' to='/contact'>
            CONTACT US
          </StyledLink>
        </LinkContainer>
      </StyledHeader>
    </BaseContainer>
  );
};

export default Header;

export const StyledLink = styled(Link)`
  opacity: 0;
  font-family: WorkSans;
  font-size: ${props => (props.islogo ? '20px' : '20px')};
  color: ${COLORS.LINKS};
  text-decoration: none;
  line-height: 1.2;
  letter-spacing: 4.91px;
  padding-bottom: 14px;
  /* Set up the hover */
  /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
  background-image: linear-gradient(
      ${COLORS.SECTIONTITLE},
      ${COLORS.SECTIONTITLE}
    ),
    linear-gradient(transparent, transparent);
  background-size: 0 2px, auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  animation: ${fadeIn} 1.5s;
  animation-delay: ${props => (props.delay ? props.delay : '0ms')};
  animation-fill-mode: forwards;
  transition: ${props => (props.islogo ? '' : 'all 1s ease-out')};

  :hover {
    background-size: ${props =>
      props.islogo === true ? '' : '100% 2px, auto'};
  }
  @media only screen and (min-width: 765px) {
    font-size: ${props => (props.islogo ? '20px' : '14px')};
  }

  /* Laptop Specific */
  @media only screen and (min-width: 1224px) {
    font-size: ${props => (props.islogo ? '30px' : '14px')};
    line-height: 2.95;
    letter-spacing: 4.91px;
  }
  /* Desktop Specific */
  @media only screen and (min-width: 1824px) {
    font-size: ${props => (props.islogo ? '40px' : '20px')};
  }
`;

const StyledHeader = styled.div`
  margin-top: 58px;
  font-size: 15px;
  display: flex;
  grid-area: 1 / 2 / 2 / 12;
  align-items: center;
  justify-content: space-between;
  /* Laptop Specific */
  @media only screen and (min-width: 1224px) {
    grid-area: 1 / 2 / 2 / 12;
    justify-content: space-between;
    align-items: center;
    grid-auto-rows: min-content;
    margin-top: 70px;
  }
  /* Desktop Specific */
  @media only screen and (min-width: 1824px) {
    margin-top: 131px;
  }
`;

const LinkContainer = styled.div`
  display: none;
  /* Tablet and up */
  @media only screen and (min-width: 765px) {
    display: flex;
    > *:first-child {
      margin-right: 50px;
    }
  }
  /* Laptop Specific */
  @media only screen and (min-width: 1224px) {
    display: flex;
    > *:first-child {
      margin-right: 135px;
    }
  }
`;
