import React from 'react';
import styled from 'styled-components';
import Contact from './Contact';
import { BaseContainer } from './shared/BaseContainer';
import { COLORS } from '../styleconstants';
import { fadeIn } from './shared/Animations';

const Manifesto = () => {
  return (
    <>
      <BaseContainer>
        <StyledContainer>
          <TextContainer>
            <h1>OUR MANIFESTO</h1>
            <TextBlock>
              We cultivate and advance the next generation of humans and
              businesses to better our economy, lifestyle and culture.
            </TextBlock>
            <TextBlock delay={'2s'}>
              There are five core values our portfolio companies share:
              integrity, community, inclusivity, transparency and innovation.
            </TextBlock>
            <TextBlock delay={'4s'}>
              Our mission is to accelerate impact and drive positive change to
              create a better future for all.
            </TextBlock>
          </TextContainer>
        </StyledContainer>
      </BaseContainer>
      <Contact backgroundColor={COLORS.WHITE} />
    </>
  );
};

export default Manifesto;

const StyledContainer = styled.div`
  grid-area: 2 / 2 / 3 / -2;
`;

const TextBlock = styled.h3`
  opacity: 0;
  color: ${COLORS.LINKS};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: BerlingskeSerif;
  line-height: 1.68;
  font-size: 28px;
  letter-spacing: 0.5px;
  animation: ${fadeIn} 1.5s;
  animation-delay: ${props => (props.delay ? props.delay : '0ms')};
  animation-fill-mode: forwards;

  /* Laptop Specific */
  @media only screen and (min-width: 1224px) {
    font-size: 63px;
    line-height: 1.41;
    letter-spacing: 2.31px;
    margin-top: 0;
    margin-block-end: 1.5em;
  }
`;

const TextContainer = styled.div`
  margin-top: 82px;
  h1 {
    color: ${COLORS.LINKS};
    margin-bottom: 10px;
    font-family: WorkSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 3.44px;
    /* Tablet Specific */
    @media only screen and (min-width: 765px) {
      display: none;
    }

    /* Laptop Specific */
    @media only screen and (min-width: 1224px) {
    }
    /* Desktop Specific */
    @media only screen and (min-width: 1824px) {
    }
  }
  /* Tablet Specific */
  @media only screen and (min-width: 765px) {
    margin-top: 132px;
    padding-bottom: 190px;
  }
`;
