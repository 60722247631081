import React from 'react';
import Contact from './Contact';
import { COLORS } from '../styleconstants';
import { BaseContainer } from './shared/BaseContainer';
import styled from 'styled-components';
import { SectionTitle } from './shared/Text';
import { fadeIn, slideupNew, slidedownNew } from './shared/Animations';
import ScrollAnimation from 'react-animate-on-scroll';

const Home = () => {
  return (
    <>
      <BaseContainer>
        <StyledContainer>
          <HeaderContainer>
            <div
              style={{
                maxHeight: '200px',
                overflow: 'hidden',
              }}
            >
              <Hero delay={'200ms'}>Creating a better</Hero>
            </div>
            <div
              style={{
                display: 'flex',
                maxHeight: '200px',
                overflow: 'hidden',
              }}
            >
              <Hero delay={'400ms'}>
                tomorrow,<ItalicText>{` together.`}</ItalicText>
              </Hero>
            </div>
          </HeaderContainer>
          <SubHero>
            <SectionTitle delay='3s'>OUR COMPANIES</SectionTitle>
            <div style={{ overflow: 'hidden' }}>
              <DecorationLine offset={0} />
            </div>
          </SubHero>
          <MainElement
            title='HONOR'
            subtitle='Strategic Positioning & Leadership Cultivation'
            link='https://hello-honor.com'
          />
          <MainElement
            title='ATELIER'
            subtitle='Launch & Growth Business Strategy'
            link='https://hello-atelier.com'
          />
          <MainElement
            title='HUMANLY'
            subtitle='Digital Content & Virtual Experiences'
            link='https://hello-humanly.com'
          />
        </StyledContainer>
      </BaseContainer>
      <Contact backgroundColor={COLORS.WHITE} />
    </>
  );
};

export default Home;

const Hero = styled.div`
  opacity: 0;
  color: ${COLORS.LINKS};
  font-size: 55px;
  letter-spacing: 2.01px;
  font-family: BerlingskeSerif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  animation: ${slideupNew} 2s;
  animation-delay: ${props => (props.delay ? props.delay : '0ms')};
  animation-fill-mode: forwards;
  /* Laptop Specific */
  @media only screen and (min-width: 1224px) {
    font-size: 100px;
  }
  /* Desktop Specific */
  @media only screen and (min-width: 1824px) {
    font-size: 125px;
    line-height: 1.23;
    letter-spacing: 4.58px;
  }
`;

const ItalicText = styled.span`
  opacity: 0;
  color: ${COLORS.LINKS};
  font-size: 55px;
  letter-spacing: 2.01px;
  font-family: BerlingskeSerifCN;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  animation: ${fadeIn} 2s;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;
  /* Laptop Specific */
  @media only screen and (min-width: 1224px) {
    font-size: 100px;
  }
  /* Desktop Specific */
  @media only screen and (min-width: 1824px) {
    font-size: 125px;
    line-height: 1.23;
    letter-spacing: 4.58px;
  }
`;

const HeaderContainer = styled.div`
  padding-top: 84px;
  margin-bottom: 120px;
  @media only screen and (min-width: 1224px) {
    padding-top: 132px;
    margin-bottom: 100px;
  }
  @media only screen and (min-width: 1824px) {
    margin-bottom: 150px;
  }
`;

const SubHero = styled.div`
  grid-area: 2 / 2 / 3 / -3;
  margin-bottom: 200px;
  overflow: hidden;
`;

const StyledContainer = styled.div`
  grid-area: 2 / 2 / 3 / -3;
`;

const DecorationLine = styled.div`
  opacity: 0;
  border-left: 2px solid ${COLORS.SECTIONTITLE};
  height: 90px;
  margin-left: 25px;
  animation: ${slidedownNew} 1.5s;
  animation-fill-mode: forwards;
  animation-delay: 4000ms;
  /* Laptop Specific */
  @media only screen and (min-width: 1224px) {
    margin-left: 100px;
  }
  /* Desktop Specific */
  @media only screen and (min-width: 1824px) {
    height: 130px;
  }
`;

const MainElement = ({ title, subtitle, link }) => {
  return (
    <StyledElement>
      <h1>{title}</h1>
      <h3>
        <ScrollAnimation
          offset={50}
          animateOnce
          delay={100}
          animateIn={'fadeIn'}
        >
          {subtitle}
        </ScrollAnimation>
      </h3>
      <Button link={link} />
    </StyledElement>
  );
};

const StyledElement = styled.div`
  grid-area: 2 / 2 / 3 / -3;
  margin-bottom: 150px;

  h1 {
    font-size: 55px;
    line-height: 1.07;
    letter-spacing: 1.15px;
    font-family: BerlingskeSerif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: ${COLORS.LINKS};
    margin: 0;
  }
  h3 {
    font-size: 24px;
    line-height: 1.42;
    letter-spacing: 0.5px;
    font-family: BerlingskeSerif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-top: 20px;
  }

  @media only screen and (min-width: 1224px) {
    h1 {
      font-size: 150px;
      line-height: 1.56;
      letter-spacing: 3.87px;
    }
    h3 {
      font-size: 30px;
      margin-top: 0;
      line-height: 1.43;
      letter-spacing: 0.74px;
    }

    button {
    }
  }
  /* Desktop Specific */
  @media only screen and (min-width: 1824px) {
    h1 {
      font-size: 185px;
    }
    h3 {
      font-size: 35px;
    }
    button {
      letter-spacing: 4.91px;
      line-height: 2.95;
      font-size: 20px;
      width: 381px;
      height: 64px;
    }
  }
`;

const Button = ({ link }) => {
  return (
    <ButtonContainer>
      <ScrollAnimation offset={50} animateOnce delay={200} animateIn={'fadeIn'}>
        <StyledButton onClick={() => window.open(link)}>
          LEARN MORE
        </StyledButton>
      </ScrollAnimation>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  margin-top: 40px;
  @media only screen and (min-width: 1824px) {
    margin-top: 100px;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.SECTIONTITLE};
  font-family: BerlingskeSerif;
  font-size: 18px;
  border: 1px solid ${COLORS.SECTIONTITLE};
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  width: 80%;
  height: 55px;
  letter-spacing: 4.42px;
  transition: all 0.5s ease-in;
  :hover {
    background-color: ${COLORS.SECTIONTITLE};
    color: ${COLORS.BACKGROUND};
  }
  @media only screen and (min-width: 750px) {
    width: 381px;
  }
  @media only screen and (min-width: 1824px) {
    letter-spacing: 4.91px;
    line-height: 2.95;
    font-size: 20px;
    width: 381px;
    height: 64px;
  }
`;
