const { keyframes } = require('styled-components');

export const slideUp = keyframes`
  0% {
  visibility: hidden;
  opacity: 0;
  transform: translate3d(0, 20%, 0px);
  }
  100% {
  visibility: visible;
  opacity: 1;
  transform: none;
  }
`;

export const slideupNew = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 1.2em);
  }
  to { 
    opacity: 1;
  }
`;
export const slidedownNew = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -120%);
  }
  to { 
    opacity: 1;
  }
`;

export const fadeIn = keyframes`
from {
    visibility: hidden;
    opacity: 0;
  }
  to { 
    opacity: 1;
  }
  `;
