import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../styleconstants';
import { Link } from 'react-router-dom';
const Menu = ({ isOpen, handleClick }) => {
  return (
    <StyledMenu open={isOpen}>
      <MenuLink onClick={handleClick} to='/'>
        NEW LEGACY
      </MenuLink>
      <MenuLink onClick={handleClick} to='/manifesto'>
        OUR MANIFESTO
      </MenuLink>
      <MenuLink onClick={handleClick} to='/contact'>
        CONTACT US
      </MenuLink>
    </StyledMenu>
  );
};
export default Menu;

const MenuLink = styled(Link)`
  height: 59px;
  padding-bottom: 6px;
  font-family: WorkSans;
  text-decoration: none;
  font-size: 20px;
  color: ${COLORS.LINKS};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.95;
  letter-spacing: 4.91px;
`;
export const StyledMenu = styled.nav`
  display: flex;
  z-index: 9;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 93px;
  background: ${COLORS.BACKGROUND};
  height: 100vh;
  width: 100%;
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.2s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  @media only screen and (min-width: 500px) {
    display: none;
  }
`;
