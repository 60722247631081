import styled from 'styled-components';

import React from 'react';
import { COLORS } from '../styleconstants';
import { fadeIn } from './shared/Animations';

const Burger = ({ handleClick, isOpen, delay }) => {
  return (
    <StyledBurger delay={delay} onClick={handleClick} isOpen={isOpen}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Burger;

export const StyledBurger = styled.button`
opacity: 0;
animation: ${fadeIn} 1.5s;
  animation-delay: ${props => (props.delay ? props.delay : '0ms')};
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    background-color: ${COLORS.LINKS};
    width: 2rem;
    height: 2.5px;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isOpen }) =>
        isOpen ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
  /* Tablet and up */
  @media only screen and (min-width: 767px) {
    display: none;
  }
  }
`;
