import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import { createGlobalStyle } from 'styled-components';
import { COLORS } from './styleconstants';
import Manifesto from './components/Manifesto';
import Contact from './components/Contact';
import Home from './components/Home';
import Menu from './components/Sidebar';
import Disclaimer from './components/Disclaimer';

export default function BasicExample() {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <GlobalStyle />
      <Router>
        <Menu
          isOpen={menuIsOpen}
          handleClick={() => setMenuIsOpen(!menuIsOpen)}
        />
        <Header
          isOpen={menuIsOpen}
          handleClick={() => setMenuIsOpen(!menuIsOpen)}
        />
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/manifesto'>
            <Manifesto />
          </Route>
          <Route path='/contact'>
            <Contact fullHeight />
          </Route>
        </Switch>
      </Router>
      <Disclaimer />
    </>
  );
}
const GlobalStyle = createGlobalStyle`
body {
  background-color: ${COLORS.BACKGROUND};
}
`;
