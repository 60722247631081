import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../styleconstants';
import { BaseContainer } from './shared/BaseContainer';
import { SectionTitle } from './shared/Text';

const Contact = ({ backgroundColor, fullHeight }) => {
  return (
    <BaseContainer backgroundColor={backgroundColor}>
      <StyledContainer fullHeight={fullHeight}>
        <StyledGrid>
          <SectionTitle>BUILD WITH US</SectionTitle>
          <SectionText>together@hellonewlegacy.com</SectionText>
        </StyledGrid>
      </StyledContainer>
    </BaseContainer>
  );
};

export default Contact;

const StyledContainer = styled.div`
  height: ${props => (props.fullHeight ? '100vh' : '254px')};
  grid-area: 1 / 2 / 2 / 12;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  padding-top: 65px;
  /* Laptop Specific */
  @media only screen and (min-width: 1224px) {
    height: ${props => (props.fullHeight ? '100vh' : '309px')};
    padding-top: 72px;
  }
  /* Desktop Specific */
  @media only screen and (min-width: 1824px) {
    height: ${props => (props.fullHeight ? '100vh' : '409px')};
  }
`;

const StyledGrid = styled.div``;

const SectionText = styled.div`
  font-family: BerlingskeSerif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${COLORS.LINKS};
  line-height: 1.3;
  font-size: 20px;
  letter-spacing: 0.73px;
  /* Laptop Specific */
  @media only screen and (min-width: 1224px) {
    font-size: 54px;
    line-height: 1.46;
    letter-spacing: 1.98px;
  }
  /* Desktop Specific */
  @media only screen and (min-width: 1824px) {
  }
`;
