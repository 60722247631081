import styled from 'styled-components';
import { COLORS } from '../../styleconstants';
import { fadeIn } from './Animations';

export const SectionTitle = styled.div`
  opacity: 0;
  margin-bottom: 42px;
  color: ${COLORS.SECTIONTITLE};
  font-family: WorkSans;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 3.26px;
  animation: ${fadeIn} 2s;
  animation-delay: ${props => (props.delay ? props.delay : '0s')};
  animation-fill-mode: forwards;

  /* Laptop Specific */
  @media only screen and (min-width: 1224px) {
  }
  /* Desktop Specific */
  @media only screen and (min-width: 1824px) {
    font-size: 25px;
    line-height: 2.76;
    letter-spacing: 4.52px;
  }
`;
