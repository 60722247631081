import React from 'react';
import styled from 'styled-components';

const Disclaimer = () => {
  return (
    <StyledSection backgroundColor={'#3e3a3a'}>
      <TextContainer>
        <CopyrightText>
          {`Copyright © ${new Date().getFullYear()} `}{' '}
          <a target='_blank' rel='noopener noreferrer' href='/'>
            Hello New Legacy, LLC.
          </a>{' '}
          All Rights Reserved
        </CopyrightText>
      </TextContainer>
    </StyledSection>
  );
};

export default Disclaimer;

const StyledSection = styled.div`
  display: flex;
  justify-content: center;
  background-color: #3e3a3a;
  padding-top: 20px;
  padding-bottom: 20px;
  /* Desktop Specific */
  @media only screen and (min-width: 1824px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1824px) {
    align-items: center;
    justify-content: center;
  }
`;

const CopyrightText = styled.div`
  font-size: 12px;
  line-height: 1.42;
  letter-spacing: 0.19px;
  color: #f9f9f9;
  font-family: WorkSans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  a {
    color: #f9f9f9;
  }
  @media only screen and (min-width: 1824px) {
    width: 100%;
    text-align: right;
    font-size: 23px;
    letter-spacing: 0.36px;
  }
`;
